<template>
  <div class="app-container">
    <el-row class="margin-bottom-20">
      <el-col :lg="16" :xs="16"  :offset="0">
        <el-input prefix-icon="el-icon-search" size="mini" placeholder="ID " v-model="searchForm.query"
          v-on:change="handleSearchFilter()" clearable></el-input>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button @click.stop.prevent="handleSearchFilter" class="margin-left-10" size="mini" type="primary"
          icon="el-icon-search">Buscar</el-button>
      </el-col>
      <el-col :lg="2" :xs="8" :offset="0">
        <el-button class="margin-left-10" @click.stop.prevent="handleClean" size="mini" type="warning"
          icon="el-icon-delete">Limpiar</el-button>
      </el-col>
    </el-row>
    <!---- Paginacion -->
    <el-row type="flex" justify="end">
      <el-pagination @size-change="handleChangeItemPage"  :page-sizes="[25, 50, 100, 200]"  :page-size="25" layout="total,sizes,prev, pager, next" @current-change="handleCurrentChange"
        :total="parseInt(totalPages)">
      </el-pagination>
    </el-row>
    <el-row type="flex" justify="start">
        <el-button @click.prevent="handleCreate" class="margin-left-10" size="mini" type="success"
          icon="el-icon-plus">Agregar Imagen</el-button>
    </el-row>
    <el-row>
      <template>
        <el-col class="images-col" v-for="(item, index) in list" :key="index" :lg="4" :md="6" :sm="6" :xs="12" align="middle">
          <ImageCard :showOptions="true"
                     :showPreview="true"
                     :key="item._id"
                     :id="item._id"
                     :image="pathServer + item.path"
                     :description="item.alt"
                     @info="handleEdit"
                     @remove="handleDelete(index, item._id)">
                    </ImageCard>
        </el-col>
      </template>
    </el-row>
  </div>
</template>

<script>
import { search, getPage, deleteImage } from '@/api/gallery.js'
import ImageCard from '@/components/ImageCard/ImageCard.vue'
const confElement = document.getElementById('config')
const conf = JSON.parse(confElement.innerHTML)
const urlServer = conf.baseApi
export default {
  name: 'Gallery',
  components: {
    ImageCard
  },
  data () {
    return {
      pathServer: urlServer,
      searchForm: {
        query: ''
      },
      filterDateRegisterRange: '',
      totalPages: '',
      currentPage: '',
      loading: false,
      sizePerPage: 25,
      locations: null,
      types: null,
      list: [],
      galleryList: [
        {
          _id: '405392024',
          name: 'eb4a37c989dd0509e5df2e8b38cdd7',
          width: '250',
          height: '250',
          title: 'image',
          alt: 'PBosque-image',
          quality: '100',
          format: 'jpg',
          path: 'storage/images/eb4a37c989dd0509e5df2e8b38cdd7.jpg',
          updated_at: '2023-07-27T21:01:20.751+00:00'
        }
      ]
    }
  },
  mounted () {
    this.handleSearchFilter()
  },
  methods: {
    // ---- Data guides ------
    handleCreate () {
      this.$router.push('gallery/create')
    },
    handleDownloadDocument (file) {
      window.open(`${this.pathServer + file}`, '_blank')
    },
    handleEdit (id) {
      console.log('edit= ' + id)
      this.$router.push(`gallery/edit/${id}`)
    },
    handleDelete (index, id) {
      this.$confirm('Se borrará permanentemente. ¿Desea continuar?', 'Warning', {
        confirmButtonText: 'OK',
        cancelButtonText: 'Cancel',
        type: 'warning'
      })
        .then(async () => {
          await deleteImage(id)
            .then(response => {
              this.$message({
                showClose: true,
                message: '¡Se eliminó correctamente!',
                type: 'success'
              })

              this.list.splice(index, 1)
            })
            .catch(this.responseCatch)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: 'Operacion cancelada'
          })
        })
    },
    validateIsPublic (isPublic) {
      return isPublic ? 'Si' : 'No'
    },
    // --- data search ----
    async handleSearchFilter () {
      console.log('Prueba de datos')
      this.loading = true
      await search(this.searchForm)
        .then((response) => {
          console.log(response)
          this.list = response.data.data
          this.totalPages = response.data.total
          console.log(this.list)
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleChangeItemPage (val) {
      this.loading = true
      this.sizePerPage = val
      await getPage(this.currentPage, val, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    async handleCurrentChange (page) {
      this.currentPage = page
      this.loading = true
      await getPage(page, this.sizePerPage, this.searchForm)
        .then(response => {
          this.list = response.data.data
          this.totalPages = response.data.total
        })
        .catch(this.responseCatch)
        .finally(() => (this.loading = false))
    },
    handleClean () {
      this.searchForm.query = ''
      this.handleSearchFilter()
    }
  }
}
</script>

<style lang="scss">
.images-col{
  margin-top: 1.2em;
}
</style>
